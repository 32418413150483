// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-a-propos-js": () => import("./../../../src/pages/a-propos.js" /* webpackChunkName: "component---src-pages-a-propos-js" */),
  "component---src-pages-actualites-actualites-js": () => import("./../../../src/pages/actualites/actualites.js" /* webpackChunkName: "component---src-pages-actualites-actualites-js" */),
  "component---src-pages-actualites-agenda-js": () => import("./../../../src/pages/actualites/agenda.js" /* webpackChunkName: "component---src-pages-actualites-agenda-js" */),
  "component---src-pages-actualites-graph-cms-actualite-slug-js": () => import("./../../../src/pages/actualites/{GraphCmsActualite.slug}.js" /* webpackChunkName: "component---src-pages-actualites-graph-cms-actualite-slug-js" */),
  "component---src-pages-actualites-graph-cms-evenement-slug-js": () => import("./../../../src/pages/actualites/{GraphCmsEvenement.slug}.js" /* webpackChunkName: "component---src-pages-actualites-graph-cms-evenement-slug-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

